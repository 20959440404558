<template>
  <div class="top-forex-brokers__wrapper opened-bracket closed-bracket">
    <p class="sidebar__section-title title">Top Forex Brokers</p>
    <div class="top-forex-brokers__banners-wrapper sidebar__section-content">
      <a-banner
        v-for="(settings, index) in brokerListOfBannerSettings"
        :key="settings.id"
        :banner-settings="settings"
        class="top-forex-brokers__banner"
        :class="getBannerDynamicClass(index)"
        @campaign-is-empty-status="onCampaignStatusChange(index, $event)"
      />
    </div>
  </div>
</template>

<script>
import {
  BANNER_SETTINGS,
  getTopForexBrokersBannerSettingsKey
} from 'enums/banners/banner-settings'
import ABanner from 'shared/ABanner'
import { generateServerCacheKey } from '@/plugins/helper'
import { mapGetters } from 'vuex'

const BANNERS_COUNT = 21

export default {
  name: 'TopForexBrokers',
  serverCacheKey: () => generateServerCacheKey('TopForexBrokers'),
  components: { ABanner },
  data() {
    return {
      brokerListOfBannerSettings: this.$helper
        .createPrefilledArray(BANNERS_COUNT, null)
        .map(
          (el, index) =>
            BANNER_SETTINGS.SIDEBAR[
              getTopForexBrokersBannerSettingsKey(index + 1)
            ]
        ),
      bannerHiddenState: this.$helper.createPrefilledArray(BANNERS_COUNT, true)
    }
  },
  computed: {
    ...mapGetters({
      isPreviewMode: 'isPreviewMode'
    })
  },
  methods: {
    getBannerDynamicClass(index) {
      return {
        empty: !this.isPreviewMode && this.bannerHiddenState[index]
      }
    },
    onCampaignStatusChange(index, isEmpty) {
      this.bannerHiddenState = this.$helper.replaceElementInArrayByIndex(
        this.bannerHiddenState,
        index,
        isEmpty
      )
    }
  }
}
</script>

<style lang="scss" scoped>
$banner-margin-right-sidebar-lg: 26px;
$banner-margin-left-right-sidebar-sm: 20px;

.top-forex-brokers__wrapper {
  width: 100%;

  .top-forex-brokers__banners-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -#{$banner-margin-right-sidebar-lg};

    @include sidebar-small-size {
      margin: 8px 0 0;
    }
  }

  .top-forex-brokers__banner {
    transition: none;
    margin: 20px $banner-margin-right-sidebar-lg 0 0;

    @include sidebar-small-size {
      margin: 12px $banner-margin-left-right-sidebar-sm;
    }

    /deep/ .preview::after {
      font-size: 12px;
    }

    &.empty {
      visibility: hidden;
      width: 0;
      height: 0;
      overflow: hidden;
      margin: 0;
    }
  }
}
</style>
